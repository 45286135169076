.pdf-container {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
}

.pdf-page {
    position: relative;
}

.table-container {
    overflow-x: auto;
    max-width: 100%;
}

.header-row {
    background-color: #ce1313;
    /* Change this color to your preference */
    font-weight: bold;
}

.border-none {
    border: none !important;
}

.grab {
    cursor: grab;
}

.grabbing {
    cursor: grabbing;
}
