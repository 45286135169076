body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-highlight {
  color: #000000 !important;
  background: linear-gradient(90deg,
      rgba(255, 250, 88, 0.1) 0%,
      rgba(255, 250, 88, 0.8) 10%,
      rgba(255, 250, 88, 0.7) 90%,
      rgba(255, 250, 88, 0.1) 100%);
  padding: 2px 4px;
  /* Adds some spacing around the text for the highlight effect */
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  /* Slight shadow for depth */
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
  display: inline;
}

.small-image {
  width: 50vh;
  /* Adjust the value as needed */
  height: auto;
}

.nutritionFactsCard {
  font-family: Arial, sans-serif;
  border: 1px solid #000;
  padding: 10px;
}

h3 {
  font-size: 18px;
  text-align: center;
  border-bottom: 2px solid #000;
  margin-bottom: 8px;
  margin-top: 0;
}

.text-align-center {
  text-align: center;
}

hr {
  border-top: 1px solid #000;
  margin: 6px 0;
}

.mt-2 {
  margin-top: 6px;
}

strong {
  font-weight: bold;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(2px);
  /* Blurring effect */
  z-index: 10;
  /* Ensure it's above the NutritionLabel content */
}

.tooltip {
  position: absolute;
  text-align: center;
  padding: 10px;
  font: 12px sans-serif;
  background: white;
  border: 1px solid #aaa;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.1s;
}

.aspect-ratio-box {
  position: relative;
  width: 100%;
  padding-bottom: 90%;
  /* 540 / 600 = 0.9 */
}

.aspect-ratio-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.img-fluid {
  object-fit: cover;
  /* This will make sure to cover the area and maintain aspect ratio */
  width: 80%;
  height: 100%;
}


/* Custom styles for larger title and content */
.large-title {
  font-size: 3.5vh;
  /* Adjust size as needed */
  font-weight: bold;
  /* Making the title bold for emphasis */
}

.large-content {
  font-size: 2.5vh;
  /* Adjust size as needed */
}

.large-cta {
  font-size: 2.5vh;
  font-weight: bold;
}
